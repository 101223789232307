import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { showHelpScoutPopUp } from '@app/services/helpscout';
import { getMubiShopUrl } from '@app/services/promo-utils';

import { useGetGoAvailabilityForCountry } from '@app/hooks/helpers/useGetGoAvailabilityForCountry';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import Link from '@app/components/Link';

const jobsPageUrl = process.env.JOBS_SITE_URL;

const FooterAppLinks = () => {
  const { t, lang } = useTranslation('common');

  const geoLocation = useAppSelector(state => state.user.geoLocation);

  const { isPremiumPlanInCountry } = useGetGoAvailabilityForCountry();

  const showMenuHelpScoutPopUp = e => {
    showHelpScoutPopUp(e, lang);
  };

  return (
    <>
      <DesktopGrid>
        <InnerContent>
          <LinksList>
            <li>
              <Link href="/about">
                {t('common:common.subheader_nav.about')}
              </Link>
            </li>
            <li>
              <Link href="/devices">
                {t('common:common.subheader_nav.ways_to_watch')}
              </Link>
            </li>
            <li>
              <UnstyledButton onClick={showMenuHelpScoutPopUp}>
                {t('common:common.subheader_nav.help')}
              </UnstyledButton>
            </li>
          </LinksList>
        </InnerContent>
        <InnerContent>
          <LinksList>
            <li>
              <Link href="/memberships">
                {t('common:common.footer.memberships')}
              </Link>
            </li>
            <li>
              <Link href="/student">
                {t('common:common.subheader_nav.students')}
              </Link>
            </li>
            <li>
              <Link href="/terms_of_service">
                {t('common:common.subheader_nav.terms')}
              </Link>
            </li>
          </LinksList>
        </InnerContent>
      </DesktopGrid>
      <DesktopGrid>
        <InnerContent>
          <LinksList>
            <li>
              <Link href="/gifts">{t('common:common.subheader_nav.gift')}</Link>
            </li>
            {jobsPageUrl ? (
              <li>
                <a href={jobsPageUrl}>
                  {t('common:common.subheader_nav.jobs')}
                </a>
              </li>
            ) : null}
            <li>
              <a href="/contribute">
                {t('common:common.subheader_nav.contribute')}
              </a>
            </li>
          </LinksList>
        </InnerContent>
        <InnerContent>
          <LinksList>
            {isPremiumPlanInCountry ? (
              <li>
                <Link href="/go">MUBI GO</Link>
              </li>
            ) : null}
            <li>
              <Link href="/notebook">NOTEBOOK</Link>
            </li>
            {geoLocation === 'GB' || geoLocation === 'US' ? (
              <li>
                <a href={getMubiShopUrl(geoLocation)}>SHOP</a>
              </li>
            ) : null}
          </LinksList>
        </InnerContent>
      </DesktopGrid>
    </>
  );
};

export default FooterAppLinks;

const DesktopGrid = styled.div`
  width: max-content;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: initial;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    column-gap: 65px;
  }
`;

const InnerContent = styled.div`
  display: flex;
`;

const LinksList = styled.ul`
  line-height: 30px;

  a,
  button {
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    transition: color 0.2s;
    color: ${props => props.theme.color.darkGray};
    &:visited {
      color: ${props => props.theme.color.darkGray};
    }
    &:hover {
      color: ${props => props.theme.color.darkText};
      text-decoration: none;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    a,
    button {
      font-size: 14px;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    line-height: normal;
  }
`;
