import { ReactElement } from 'react';
import styled from '@emotion/styled';

import {
  getSocialMediaLink,
  SocialMediaApps,
} from '@app/services/social-media';

import { color as themeColors } from '@app/themes/mubi-theme';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import {
  FacebookRoundIcon,
  InstagramIcon,
  ThreadsLogoIcon,
  TikTokLogoIcon,
  XIcon,
  YouTubeIcon,
} from '@app/components/icons/SocialMediaIcons';

const FooterSocialMedia = () => {
  const geoLocation = useAppSelector(state => state.user.geoLocation);

  return (
    <SocialMediaList>
      {socialMediaLogos.map(socialMediaApp => (
        <li key={socialMediaApp.name}>
          <a
            href={getSocialMediaLink(
              socialMediaApp.name,
              geoLocation.toLocaleLowerCase(),
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {socialMediaApp.icon}
          </a>
        </li>
      ))}
    </SocialMediaList>
  );
};

export default FooterSocialMedia;

const SocialMediaList = styled.ul`
  display: flex;
  align-items: center;
  padding-top: 15px;

  li {
    padding-right: 15px;

    &:last-child {
      padding-right: 0;
    }

    a {
      transition: opacity 0.3s;
    }

    a:hover {
      opacity: 0.6;
    }
  }
`;

const socialMediaLogos: Array<{
  name: SocialMediaApps;
  icon: ReactElement;
}> = [
  {
    name: 'instagram',
    icon: <InstagramIcon color={themeColors.black} width="18px" />,
  },
  {
    name: 'facebook',
    icon: <FacebookRoundIcon color={themeColors.black} width="20px" />,
  },
  {
    name: 'tiktok',
    icon: <TikTokLogoIcon color={themeColors.black} width="16px" />,
  },
  {
    name: 'twitter',
    icon: <XIcon color={themeColors.black} width="18px" />,
  },
  {
    name: 'threads',
    icon: <ThreadsLogoIcon color={themeColors.black} width="16px" />,
  },
  {
    name: 'youtube',
    icon: <YouTubeIcon color={themeColors.black} width="21px" />,
  },
];
