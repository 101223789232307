import { isAndroid, isIOS } from 'react-device-detect';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { getImageUrl } from '@app/services/images';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import AppStoreBadge from '@app/components/AppStoreBadge';
import FooterAppLinks from '@app/components/footer/FooterAppLinks';
import FooterSocialMedia from '@app/components/footer/FooterSocialMedia';
import { MubiLogoIcon } from '@app/components/icons/LogoIcons';
import ImageBase from '@app/components/ImageBase';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';

const qrCodeUrl = `qr-code-${
  process.env.MUBI_ENV === 'production' ? 'prod' : 'dev'
}.png`;

const Footer = () => {
  const { t } = useTranslation('common');

  const activatedMobileDevice = useAppSelector(
    state => state.user.user?.activated_mobile_device,
  );

  const isMobile = isIOS || isAndroid;

  return (
    <FooterContainer>
      <PageSection bgColor="midBackground" useTodayMQs>
        <FooterContent>
          <LogoContainer>
            <Link href="/">
              <MubiLogoIcon width="112px" />
            </Link>
          </LogoContainer>
          <LinkGrid>
            <SocialMediaContent>
              <div>
                <SocialMediaHeader>
                  {t('common:common.footer.follow_us_on')}
                </SocialMediaHeader>
                <FooterSocialMedia />
              </div>
              {isMobile ? (
                <AppStoreBadgeContainer>
                  <AppStoreBadge platform={isIOS ? 'ios' : 'android'} />
                </AppStoreBadgeContainer>
              ) : null}
            </SocialMediaContent>
            <FooterAppLinks />
          </LinkGrid>
          {!isMobile && !activatedMobileDevice ? (
            <QrCode>
              <div>
                <ImageBase
                  alt="QR code"
                  src={getImageUrl(
                    `/static/images/footer/qr-codes/${qrCodeUrl}`,
                  )}
                />
                <p>{t('common:common.footer.scan_to_get_the_app')}</p>
              </div>
            </QrCode>
          ) : null}
        </FooterContent>
      </PageSection>
    </FooterContainer>
  );
};

export default Footer;

export const footerStyles = props => css`
  width: 100%;
  height: 385px;

  @media (min-width: ${props.theme.mqNew.tablet}) {
    height: 325px;
  }

  @media (min-width: ${props.theme.mqNew.desktop}) {
    height: 270px;
  }
`;

const FooterContainer = styled.div`
  ${footerStyles}
`;

const FooterContent = styled.div`
  position: relative;
  padding-top: 25px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-top: 45px;
  }
`;

const LogoContainer = styled.div`
  display: inline-block;
  padding-bottom: 40px;
  font-size: 14px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-bottom: 15px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding-bottom: 80px;
  }
`;

const LinkGrid = styled.div`
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 200px 135px;
  padding-bottom: 100px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-bottom: 0;
    grid-template-columns: 190px repeat(2, 1fr);
    column-gap: 65px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    grid-template-columns: 190px repeat(2, 1fr) minmax(150px, auto);
    column-gap: 50px;
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    column-gap: 65px;
  }
`;

const SocialMediaContent = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  bottom: 25px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: static;
  }
`;

const SocialMediaHeader = styled.div`
  font-weight: 500;
`;

const AppStoreBadgeContainer = styled.div`
  width: 109px;
  height: 33px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: absolute;
    right: 0;
  }
`;

const QrCode = styled.div`
  display: none;
  font-size: 14px;
  font-weight: bold;
  flex-direction: column;
  align-items: flex-end;
  max-width: 170px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  p {
    padding-top: 5px;
    text-align: right;
  }

  img {
    display: block;
    height: 85px;
    width: 85px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: flex;
    justify-content: flex-end;
  }
`;
