import dynamic from 'next/dynamic';
import styled from '@emotion/styled';

import { footerStyles } from '@app/components/footer/FooterContent';

const FooterContent = dynamic(
  () =>
    import(
      /* webpackChunkName: "FooterContent" */
      '@app/components/footer/FooterContent'
    ),
  {
    ssr: false,
    loading: () => null,
  },
);

const Footer = () => (
  <Container>
    <FooterContent />
  </Container>
);

export default Footer;

const Container = styled.footer`
  ${footerStyles}
`;
